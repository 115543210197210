






















































import { Nullable } from '@/types'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const BookingStore = namespace('BookingStore')
import { BookingModel } from '@/core/models/BookingModel'
const RoomsStore = namespace('RoomsStore')
import { RoomModel } from '@/modules/MyRoomsPage/models/RoomModel'
import { toLocalFullDate } from '@/utils/dateFormatter'

import TimeRuler from './TimeRuler.vue'
import TimeGrid from './TimeGrid.vue'
import TimeNow from './TimeNow.vue'
import RoomLocation from './RoomLocation.vue'
import RoomTime from './RoomTime.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import Spinner from '@/components/Spinner.vue'

import VueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

export interface DayInterface {
  bookings: number
  canceled: number
}

@Component({
  name: 'CalendarDay',
  components: {
    TimeRuler,
    TimeGrid,
    TimeNow,
    RoomLocation,
    RoomTime,
    BaseIcon,
    BaseButton,
    Spinner,
    VueCustomScrollbar,
  },
})
export default class extends Vue {
  @Prop({ type: Date, default: new Date() })
  value!: Date

  @Prop({ type: String, default: '' })
  calendarKey!: string

  @Watch('calendarKey')
  onCalendarKeyChanged(): void {
    this.getCurrentBookings()
  }

  @Watch('value')
  onValueChanged(): void {
    this.getCurrentBookings()
  }

  get today(): Date {
    return new Date(new Date().toDateString())
  }

  get locations(): Map<string, RoomModel[]> {
    const map = new Map<string, RoomModel[]>()
    this.roomList.forEach((room) => {
      if (map.has(room.address)) {
        ;(map.get(room.address) as RoomModel[]).push(room)
      } else {
        map.set(room.address, [room])
      }
    })
    return map
  }

  get bookings(): Map<number, BookingModel[]> {
    const map = new Map<number, BookingModel[]>()
    this.bookingList.forEach((booking) => {
      if (map.has(booking.resourceId)) {
        ;(map.get(booking.resourceId) as BookingModel[]).push(booking)
      } else {
        map.set(booking.resourceId, [booking])
      }
    })
    return map
  }

  toLocalFullDate = toLocalFullDate
  scrollHeight = 0
  loading = false
  scrollbarSettings = {
    minScrollbarLength: 60,
  }

  @BookingStore.Action
  private getBookings!: (period: {
    from: Nullable<Date>
    to: Nullable<Date>
  }) => Promise<void>

  @BookingStore.Getter
  public bookingList!: BookingModel[]

  @RoomsStore.Getter
  public roomList!: RoomModel[]

  created(): void {
    this.getCurrentBookings()
  }

  async getCurrentBookings(): Promise<void> {
    this.loading = true
    await this.getBookings({
      from: this.value,
      to: this.daysFrom(this.value, 1),
    })
    this.loading = false
  }

  daysFrom(date: Date, days: number): Date {
    const result = new Date(date.getTime())
    result.setDate(date.getDate() + days)
    return result
  }

  getDay(date: Date): number {
    return date.getDay() === 0 ? 6 : date.getDay() - 1 // make Sunday (0) the last day
  }

  prev(): void {
    const date = this.daysFrom(this.value, -1)
    this.$emit('input', date)
  }

  next(): void {
    const date = this.daysFrom(this.value, 1)
    this.$emit('input', date)
  }
}
