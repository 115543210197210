








import { Component, Vue, Prop } from 'vue-property-decorator'
import { toLocalTime } from '@/utils/dateFormatter'

@Component({
  name: 'TimeNow',
})
export default class extends Vue {
  @Prop({ type: Number, default: 144 })
  unit!: number

  @Prop({ type: Date, default: new Date() })
  current!: Date

  get currentDate(): Date {
    return new Date(this.current.toDateString())
  }

  get visible(): boolean {
    return this.current.toDateString() === this.now.toDateString()
  }

  get duration(): number {
    return Math.floor((this.now.getTime() - this.currentDate.getTime()) / 1000)
  }

  get offset(): number {
    return Math.floor((this.duration * this.unit) / 3600) + 50
  }

  toLocalTime = toLocalTime
  now = new Date()
  interval!: number

  created(): void {
    this.interval = setInterval(() => {
      this.now = new Date()
    }, 1000)
  }

  beforeDestroy(): void {
    clearInterval(this.interval)
  }
}
