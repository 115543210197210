























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { RoomModel } from '@/modules/MyRoomsPage/models/RoomModel'
import { BookingModel } from '@/core/models/BookingModel'
import BaseIcon from '@/components/base/BaseIcon.vue'
import { toLocalDuration } from '@/utils/durationFormatter'
import { toLocalCurrency } from '@/utils/currencyFormatter'

@Component({
  name: 'RoomTime',
  components: {
    BaseIcon,
  },
})
export default class extends Vue {
  @Prop({ type: Date, required: true })
  current!: Date

  @Prop({ type: Object, required: true })
  room!: RoomModel

  @Prop({ type: Array, default: () => [] })
  bookings!: BookingModel[]

  @Prop({ type: Number, default: 144 })
  unit!: number

  get preparedBookings(): {
    booking: BookingModel
    duration: number
    width: number
    offset: number
    showTooltip: boolean
  }[] {
    return this.bookings.map((booking) => {
      const start = new Date(booking.start)
      const end = new Date(booking.end)
      const duration = Math.floor((end.getTime() - start.getTime()) / 1000)
      const width = Math.floor((duration * this.unit) / 3600)
      const from = Math.floor((start.getTime() - this.current.getTime()) / 1000)
      const offset = Math.floor((from * this.unit) / 3600) + 50
      const center = offset + Math.floor(width / 2)
      const showTooltip =
        center < 100 ||
        center > this.unit * 24 ||
        duration > 3 * 3600 ||
        duration <= 3600 / 2
      return {
        booking,
        duration,
        width,
        offset,
        showTooltip,
      }
    })
  }

  mounted(): void {
    const element = this.$refs['title'] as HTMLElement
    this.isEllipsis =
      element.clientWidth < element.scrollWidth ||
      element.clientHeight < element.scrollHeight
  }

  toLocalDuration = toLocalDuration
  toLocalCurrency = toLocalCurrency
  isEllipsis = false
}
