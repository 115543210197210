




























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'TimeRuler',
})
export default class extends Vue {
  @Prop({ type: Number, default: 144 })
  unit!: number

  mask(value: string): (RegExp | string)[] {
    const hours =
      value.length > 1
        ? [/[0-2]/, value.charAt(0) === '2' ? /[0-3]/ : /[0-9]/]
        : ['0', value.charAt(0) === '0' ? '0' : /[0-9]/]
    return [...hours, ':', '0', '0']
  }
}
